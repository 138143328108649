const scrollbar = require("./smooth");

(function () {

  var hash = location.hash.substr(1);
  if (!$(".tab-link").length) {
    return;
  }

  // check the transition to the tab by reference
  if (!hash) {
    $(".tab-link:first").addClass("active");
  } else {
    hash &&
      $('.tab-link[href="#' + hash + '"]').addClass("active") &&
      tabScrollTop();
  }

  const activLink = $(".tab-link.active").attr("href");
  activeTab(activLink);

  $(".tab-link").on("click", function (e) {
    e.preventDefault();
    const $this = $(this),
      currentTab = $this.attr("href");

    if (!$this.is(".active")) {
      if (!$(".js-fade-in").length) {
        activeTab(currentTab);
        tabScrollTop();
      } else {
        activeTabFadeIn(currentTab, ".js-fade-in", 400);
        tabScrollTop();
      }
    } else {
      return;
    }
  });
})();

/*=== show active tab ===*/
function activeTab(tab) {
  $(".tab-link").removeClass("active");
  $('.tab-link[href="' + tab + '"]').addClass("active");
  $(".tab").removeClass("active");
  $(tab).addClass("active");
  ScrollTrigger.refresh();
}

/*=== show active tab with fadeIn animation ===*/
function activeTabFadeIn(tab, wrapper) {
  gsap.to(wrapper, {
    autoAlpha: 0,
    duration: 0.3,
    onComplete: () => {
      const cb = () => {
        gsap.to(wrapper, { autoAlpha: 1, duration: 0.3 });
        ScrollTrigger.removeEventListener("refresh", cb);
      };

      ScrollTrigger.addEventListener("refresh", cb);

      activeTab(tab);
    },
  });
}

/*=== scroll to the top of tabs navigation ===*/
function tabScrollTop() {
  if (!$(".tab-top").length) {
    return;
  }

  scrollbar.scrollIntoView($('.tab-top')[0])

  // const tabTop = $(".tab-top").offset().top;

  // console.log(tabTop);
  // const navHeight = $(".tab-nav").height();
  // const topPosition = tabTop - navHeight - 10;

  // $("html, body").animate(
  //   {
  //     scrollTop: topPosition,
  //   },
  //   "slow"
  // );
}
