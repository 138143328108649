// window.addEventListener("pageshow", function (event) {
//   var historyTraversal =
//     event.persisted ||
//     (typeof window.performance != "undefined" &&
//       window.performance.navigation.type === 2);
//   if (historyTraversal) {
//     // Handle page restore.
//     window.location.reload();
//   }
// });

$(document).ready(() => {
  window.myapp = {
    body: $(document.body),
  };

  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require("./main.js");
  require("./target-blank.js");
  require("./smooth.js");
  require("./cursor.js");
  require("./slider.js");
  require("./tabs.js");
  require("./sticky.js");
  require("./post-filter.js");
  require("./scroll-top.js");
  require("./menu.js");
  require("./magnetic.js");

  function loadHandler() {
    require("./animation.js");
    $(".preloader").fadeOut();
  }

  if (document.readyState === "complete") {
    loadHandler();
  } else {
    $(window).on("load", loadHandler);
  }
});
