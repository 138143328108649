const siteWrapperScrollBar = Scrollbar.init(
  document.querySelector(".site-wrapper"),
  // {
  //   delegateTo: window,
  // }
);

siteWrapperScrollBar.setPosition(0, 0);
siteWrapperScrollBar.track.xAxis.element.remove();

ScrollTrigger.scrollerProxy(".site-wrapper", {
  scrollTop(value) {
    if (arguments.length) {
      siteWrapperScrollBar.scrollTop = value;
    }
    return siteWrapperScrollBar.scrollTop;
  },
});

siteWrapperScrollBar.addListener(ScrollTrigger.update);

const darkSections = "";

function checkInverse() {
  if (!darkSections) return;
  const isVisible = siteWrapperScrollBar.isVisible(
    document.querySelector(darkSections)
  );

  $(".menu").toggleClass("menu--inverse", isVisible);
}

checkInverse();
siteWrapperScrollBar.addListener(checkInverse);

module.exports = siteWrapperScrollBar;
