(function () {
    var quoteIndex = 1;
    if (!$('.quote').length) {
        return
    }

    quoteRotator();
    setInterval(quoteRotator, 10000);

    function quoteRotator() {
        $(".quote").hide();
        $(".quote" + quoteIndex).fadeIn(3000);

        var quoteCount = $('.quote').length;
        quoteIndex++;
        if (quoteIndex > quoteCount) {
            quoteIndex = 1;
        }
    }
})()