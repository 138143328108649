class Menu {
  constructor() {
    this.el = $(".menu");
    this.opened = !1;
    this.toggleBtn = this.el.find(".menu-btn");
    this.contactBtn = this.el.find(".menu__mail");
    this.box = this.el.find(".menu__box");
    this.backdrop = this.el.find(".menu__backdrop");
    this.content = this.el.find(".menu__content");
    this.body = this.el.find(".menu__body");
    this.footer = this.el.find(".menu__footer");
    this.nav = this.el.find(".menu__list");
    this.navItem = this.nav.find(".menu__list li");
    this.tlClose = this.tlHide();
    this.tlOpen = this.tlShow();

    this.el.on("wheel", (e) => {
      if (this.opened) {
        e.preventDefault();
      }
    });

    const self = this;

    window.addEventListener('beforeunload', (event) => {
      return self.hide();
    });

    this.toggleBtn.on("click", function () {
      return self.toggle();
    });
    this.backdrop.on("click", function () {
      return self.hide();
    });
    this.tlClose.eventCallback("onComplete", function () {
      return self.box.hide();
    });
  }

  toggle() {
    this.opened ? this.hide() : this.show();
  }

  show() {
    if (this.opened) return;

    this.opened = true;
    this.el.addClass("menu--open");
    this.box.show();
    this.tlClose.pause();
    this.tlOpen.play(0);
  }

  hide() {
    if (!this.opened) return;

    this.opened = false;
    this.el.removeClass("menu--open");
    this.box.show();
    this.tlOpen.pause();
    this.tlClose.play(0);
  }

  tlShow() {
    const tl = gsap.timeline({
      paused: !0,
    });

    tl.fromTo(
      this.backdrop,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.7,
      },
      0
    )
      .fromTo(
        this.content,
        {
          backgroundColor: "#efefef",
          x: "100%",
        },
        {
          backgroundColor: "#ffffff",
          x: "0%",
          ease: "expo.out",
          duration: 1.3,
        },
        0
      )
      .fromTo(
        this.body,
        { x: "-35%" },
        {
          x: "0%",
          ease: "expo.out",
          duration: 1.3,
        },
        0
      )
      .fromTo(
        this.body,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.6,
        },
        0.2
      )
      .fromTo(
        this.footer,
        { x: "-35%" },
        {
          x: "0%",
          ease: "expo.out",
          duration: 1.3,
        },
        0
      )
      .fromTo(
        this.footer,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.6,
        },
        0.2
      );

    return tl;
  }

  tlHide() {
    const tl = gsap.timeline({
      paused: !0,
    });

    return (
      tl.fromTo(
        this.backdrop,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.4,
        },
        0
      ),
      tl.fromTo(
        this.body,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.2,
        },
        0
      ),
      tl.fromTo(
        this.footer,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.2,
        },
        0
      ),
      tl.fromTo(
        this.content,
        {
          x: "0%",
        },
        {
          x: "100%",
          duration: 0.5,
        },
        0
      ),
      tl
    );
  }
}

new Menu();
