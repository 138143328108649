(function () {
  var filter = window.location.hash.replace("#", "."),
    $filterButton = $('[data-filter="' + filter + '"]'),
    $allPostButton = $('[data-filter="*"]');

  if (filter && $("[data-filter]").length) {
    activateFilter($filterButton);
  }

  $(window).on("hashchange", function (e) {
    var filter = window.location.hash.replace("#", ".");
    var $filterButton = $('[data-filter="' + filter + '"]');
    if (filter) {
      activateFilter($filterButton);
    } else {
      activateFilter($allPostButton);
    }
  });

  $("#filters").on("click", "[data-filter]", function (e) {
    var $this = $(this);
    activateFilter($this);
  });
})();

/*=== show active category ===*/
function activateFilter(button) {
  var filterValue = button.attr("data-filter"),
    $wrapper = button.closest(".main").find(".js-posts"),
    $articles = button.closest(".main").find(".js-post");

  if (!button.is(".active")) {
    $("#filters [data-filter]").removeClass("active");
    button.addClass("active");
    postFilter(filterValue, $wrapper, $articles, 400);
    filterValue != "*" && filterValue != "underfind"
      ? (window.location.hash = filterValue.replace(".", ""))
      : history.pushState("", document.title, window.location.pathname);
  } else {
    return;
  }
}

/*=== filter posts by category ===*/
function postFilter(filterValue, wrapper, articles, time) {
  var count = 0;
  wrapper.fadeOut(time);
  setTimeout(function () {
    articles.each(function () {
      let $this = $(this);
      if ($this.is(filterValue)) {
        count++;
        evenItem(count, $this);
        $this.show();
      } else {
        $this.hide();
      }
    });

    ScrollTrigger.refresh();
  }, time);
  wrapper.fadeIn(time);
}

/*=== add class while filtering ===*/
function evenItem(index, item) {
  if (index % 2 == 0) {
    item.addClass("even");
  } else {
    item.removeClass("even");
  }
}
