// *** Magnetic ***
const magneticOptions = {
  y: 0.2,
  x: 0.2,
  s: 0.2,
  rs: 0.7,
};

$("[data-magnetic], .cta-ripple").each(function () {
  addMagnetic(this);
});

function moveElement(ele, x, y, duration) {
  gsap.to(ele, {
    y,
    x,
    force3D: true,
    overwrite: true,
    duration,
  });
}

function addMagnetic(ele) {
  const $ele = $(ele);

  const options = {
    ...magneticOptions,
    ...($ele.data("magnetic") || {}),
  };

  let x = 0,
    y = 0,
    height = 0,
    width = 0;

  $(ele).on("mouseenter", function () {
    y = $ele.offset().top - $(window).scrollTop();
    x = $ele.offset().left - $(window).scrollLeft();
    width = $ele.outerWidth();
    height = $ele.outerHeight();
  });

  $(ele).on("mousemove", function (e) {
    const moveX = (e.clientX - x - width / 2) * options.x;
    const moveY = (e.clientY - y - height / 2) * options.y;
    moveElement(this, moveX, moveY, options.s);
  });

  $(ele).on("mouseleave", function (e) {
    moveElement(this, 0, 0, options.rs);
  });
}
