// (function () {
//   const siteWrapperScrollBar = require("./smooth");

//   const update = ({ offset }) => {
//     if ((offset ? offset.y : 1) > window.innerHeight) {
//       gsap.to(".back-to-top", { autoAlpha: 1, scale: 1 });
//     } else {
//       gsap.to(".back-to-top", { autoAlpha: 0, scale: 0 });
//     }
//   };

//   //Check to see if the window is top if not then display button
//   // $(window).on("load", update);

//   //Click event to scroll to top
//   $(".back-to-top").click(function (e) {
//     e.preventDefault();
//     siteWrapperScrollBar.scrollTo(0, 0, 1000);
//     // $("html, body").animate({ scrollTop: 0 }, 800);
//     return false;
//   });

//   siteWrapperScrollBar.addListener(update);
// })();
