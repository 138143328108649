const debounce = require("./debounce");

function isTouchDevice() {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
}

function checkIsTouchDevice() {
  const bool = isTouchDevice();
  $("body").toggleClass("touch-device", bool);
  window.myapp.isTouchDevice = bool;
}

$(window).on("resize", debounce(checkIsTouchDevice, 1000));
checkIsTouchDevice();

/*=== Gallery alignment ===*/
(function () {
  if (!$(".app-gallery__item").length) {
    return;
  }
  if ($(".app-gallery__item").length < 4) {
    $(".app-gallery__list").addClass("small");
  }
})();

/*=== Validation on contact form ===*/
(function () {
  if (!$("#contact-form").length) {
    return;
  }

  $("#contact-form").validate();
})();

/*=== Textarea auto height ===*/
(function () {
  let textareas = document.querySelectorAll(".txta"),
    hiddenDiv = document.createElement("div"),
    content = null;

  for (let j of textareas) {
    j.classList.add("txtstuff");
  }

  hiddenDiv.classList.add("txta");
  hiddenDiv.style.display = "none";
  hiddenDiv.style.whiteSpace = "pre-wrap";
  hiddenDiv.style.wordWrap = "break-word";

  for (let i of textareas) {
    (function (i) {
      i.addEventListener("input", function () {
        i.parentNode.appendChild(hiddenDiv);
        i.style.resize = "none";
        i.style.overflow = "hidden";
        content = i.value;
        content = content.replace(/\n/g, "<br>");
        hiddenDiv.innerHTML = content + '<br style="line-height: 3px;">';
        hiddenDiv.style.visibility = "hidden";
        hiddenDiv.style.display = "block";
        i.style.height = hiddenDiv.offsetHeight + "px";
        hiddenDiv.style.visibility = "visible";
        hiddenDiv.style.display = "none";
      });
    })(i);
  }
})();

/*=== Remove label in form ===*/
(function () {
  $(".contact-form__item input, .contact-form__item textarea").on(
    "input",
    function () {
      let $this = $(this);
      if ($this.val() != "") {
        $this
          .closest(".contact-form__item")
          .find(".js-label-off")
          .css("opacity", "0");
      } else {
        $this
          .closest(".contact-form__item")
          .find(".js-label-off")
          .css("opacity", "1");
      }
    }
  );
})();

/*=== Add input-file name ===*/
(function () {
  var input = $(".upload-file__input");
  var infoArea = $(".upload-file__name");

  input.on("change", showFileName);

  function showFileName(e) {
    var filename = input.val().split("\\").pop();
    infoArea.html(filename);
  }
})();
