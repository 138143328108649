$(".slider").slick({
  centerMode: true,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

$(".slider-full").slick({
  centerMode: true,
  arrows: false,
  dots: false,
  autoplay: false,
  autoplaySpeed: 2000,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [],
});

$('.industries__list').slick({
  // centerMode: true,
  prevArrow: $('.industries__list').find('[data-slider-hover-prev]'),
  nextArrow: $('.industries__list').find('[data-slider-hover-next]'),
  dots: false,
  speed: 1400,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchMove: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
      },
    },
  ],
});

/*Slider only on mobile*/
$(window).on("load resize orientationchange", function () {
  $(".app-gallery__list").each(function () {
    var $gallery = $(this);
    if ($(window).width() > 768) {
      if ($gallery.hasClass("slick-initialized")) {
        $gallery.slick("unslick");
      }
    } else {
      if (!$gallery.hasClass("slick-initialized")) {
        $gallery.slick({
          centerMode: true,
          arrows: false,
          dots: false,
          mobileFirst: true,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
    }
  });
});
