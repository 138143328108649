(function () {
  class Cursor {
    constructor(ele) {
      this.app = window.myapp;
      this.ele = $(ele);

      this.ele = $('<div class="cursor"></div>');
      this.text = $('<div class="cursor__text"></div>');

      this.speed = 0.7;
      this.app.isTouchDevice || this.init();
    }

    init() {
      this.ele.append(this.text);

      this.app.body.append(this.ele).addClass("-cursor");
      this.bind();
      this.move(-500, -500, 0);
    }

    bind() {
      const self = this;

      // Big hover elements
      const bigHoverElements = ".menu__link, .cta-ripple, .menu-btn";

      $(bigHoverElements).hover(
        () => this.setState("--exclusion"),
        () => this.removeState("--exclusion")
      );

      // View elements
      const viewElements = ".post__image, .block-list__link";

      $(viewElements).hover(
        () => this.setText("VIEW"),
        () => this.removeText("VIEW")
      );

      $(document)
        .on("mouseenter", () => self.show())
        .on("mouseleave", () => self.hide())

        .on("mouseenter", "a,input,textarea,button", () =>
          self.setState("--pointer")
        )
        .on("mouseleave", "a,input,textarea,button", () =>
          self.removeState("--pointer")
        )

        .on("mouseenter", "[data-cursor]", function () {
          self.setState(this.dataset.cursor);
        })
        .on("mouseleave", "[data-cursor]", function () {
          self.removeState(this.dataset.cursor);
        })

        .on("mouseenter", "[data-cursor-text]", function () {
          self.setText(this.dataset.cursorText);
        })
        .on("mouseleave", "[data-cursor-text]", function () {
          self.removeText();
        })

        .on("mouseenter", "[data-cursor-stick]", function () {
          self.setStick(this.dataset.cursorStick);
        })
        .on("mouseleave", "[data-cursor-stick]", function () {
          self.removeStick();
        })

        .on("mouseenter", "[data-slider-hover-prev]", function () {
          self.setText("PREV");
        })
        .on("mouseleave", "[data-slider-hover-prev]", function () {
          self.removeText();
        })

        .on("mouseenter", "[data-slider-hover-next]", function () {
          self.setText("NEXT");
        })
        .on("mouseleave", "[data-slider-hover-next]", function () {
          self.removeText();
        })

        .on("mousedown", () => self.setState("--active"))
        .on("mouseup", () => self.removeState("--active"))

        .on("mousemove", (e) => {
          if (self.pos) {
            self.vel = {
              x: e.clientX - self.pos.x,
              y: e.clientY - self.pos.y,
            };
          }

          self.pos = {
            x: self.stick
              ? self.stick.x - 0.15 * (self.stick.x - e.clientX)
              : e.clientX,
            y: self.stick
              ? self.stick.y - 0.15 * (self.stick.y - e.clientY)
              : e.clientY,
          };

          self.update();
        });
    }

    setState(classname) {
      this.ele.addClass(classname);
    }

    removeState(classname) {
      this.ele.removeClass(classname);
    }

    update() {
      this.move();
      this.show();
    }

    show() {
      if (this.visible) return;

      clearInterval(this.visibleInt);

      this.ele.addClass("--visible");

      this.visibleInt = setTimeout(() => (this.visible = true));
    }

    hide() {
      clearInterval(this.visibleInt);

      this.ele.removeClass("--visible");

      this.visibleInt = setTimeout(() => (this.visible = false), 300);
    }

    setStick(selector) {
      const $target = $(selector);

      if (!$target.length) return;

      var rect = $target.get(0).getBoundingClientRect();

      this.stick = {
        y: rect.top + $target.height() / 2,
        x: rect.left + $target.width() / 2,
      };

      this.move(this.stick.x, this.stick.y, 5);
    }

    removeStick() {
      this.stick = null;
    }

    setText(text) {
      this.text.html(text);
      this.setState("--text");
    }

    removeText(text) {
      this.text.html("");
      this.removeState("--text");
    }

    move(x, y, duration) {
      gsap.to(this.ele, {
        x: x || this.pos.x,
        y: y || this.pos.y,
        force3D: true,
        overwrite: true,
        ease: "expo.out",
        duration: this.visible ? duration || this.speed : 0,
      });
    }
  }

  new Cursor();
})();
