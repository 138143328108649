const start = "top 70%";
const end = "bottom 10%";
const toggleActions = "play none none reverse";

(function () {
  // Modify Ripple Cta
  $(".cta-ripple").each(function () {
    const textContent = $(this).text().trim();

    const $viewSpan = $('<span class="cta-ripple__view">');
    const $rippleSpan = $(
      `<span class="cta-ripple__ripple"><span></span></span>`
    );

    $viewSpan.html(
      `<span class="cta-ripple__title" data-text="${textContent}">${textContent}</span>`
    );

    $(this).empty().append($viewSpan).append($rippleSpan);
  });

  gsap.defaults({ ease: "power3.out", duration: 1 });

  ScrollTrigger.defaults({ scroller: ".site-wrapper" });

  $("[data-parallax], .hero__img, .modul-img").each(function () {
    $(this).addClass("parallax");
    const image = $(this).find("img").get();

    addParallax(image || this);
  });

  homePageHeroAnimation();
  heroAnimation();

  // Footer appear
  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".main-footer",
        start: "top 90%",
        toggleActions,
      },
    })
    .from(".main-footer__top", {
      opacity: 0,
      y: "100%",
    })
    .from(
      ".main-footer__bottom-text",
      {
        opacity: 0,
        y: "100%",
      },
      "-=0.7"
    );

  $(".greeting-message").each(function () {
    gsap
      .timeline({ scrollTrigger: { trigger: this, start, end, toggleActions } })
      .from(this, { scale: 0.5, opacity: 0, duration: 2 });
  });

  $("[a-up]").each(function () {
    const delay = $(this).attr("a-delay");
    addFadeUp(this, delay);
  });

  $("[a-right]").each(function () {
    const delay = $(this).attr("a-delay");
    addFadeRight(this, delay);
  });

  $("[a-left]").each(function () {
    const delay = $(this).attr("a-delay");
    addFadeLeft(this, delay);
  });

  $("[a-sticky]").each(function () {
    addSticky(this);
  });

  $("[a-fade-in]").each(function () {
    const delay = $(this).attr("a-delay");
    addFadeIn(this, delay);
  });

  $("[a-batch]").each(function () {
    const targetSelector = "[a-batch] " + $(this).attr("a-batch");
    const duration = $(this).attr("a-batch-duration");
    const direction = $(this).attr("a-batch-direction") || "up";
    const max = $(this).attr("a-batch-max");
    addBatch(targetSelector, duration, direction, max);
  });

  $(".contact-form-dark").each(function () {
    addFadeUp($(this).find(".contact-form__wrapper"), 0, this);
    addFadeIn($(this).find("[type=submit]"), 300, this);
  });

  // Timeline Block
  $(".history-module").each(function () {
    gsap
      .timeline({
        defaults: { duration: 0.25, ease: "linear" },
        scrollTrigger: { trigger: this, start, end, toggleActions },
      })
      .fromTo(
        ".history-module__header-border--bottom1",
        {
          right: "68%",
          width: 0,
        },
        {
          opacity: 1,
          width: "32%",
        }
      )
      .fromTo(
        ".history-module__header-border--left",
        { height: 0 },
        { height: "100%", opacity: 1 }
      )
      .fromTo(
        ".history-module__header-border--top",
        { width: 0 },
        { width: "100%", opacity: 1 }
      )
      .fromTo(
        ".history-module__header-border--right",
        { height: 0 },
        { height: "100%", opacity: 1 }
      )
      .fromTo(
        ".history-module__header-border--bottom2",
        {
          right: 0,
          width: 0,
        },
        {
          width: "68%",
          opacity: 1,
        }
      )
      .to(".history-module__line", {
        opacity: 1,
      })
      .to(".history-module__line", {
        bottom: () =>
          $(this).find(".history-module__row:last-child").height() - 13,
        scrollTrigger: {
          trigger: ".history-module__line",
          start: "top 70%+=13",
          end: "top 70%+=13",
          endTrigger:
            ".history-module__timeline-wrapper .history-module__row:last-child",
          scrub: 0.5,
        },
      })
      .from(".history-module__timeline-wrapper", { opacity: 0 });
  });

  // Projects BG sticky text
  if ($(".post-bg").length) {
    gsap.from(".post-bg", {
      opacity: 0,
      duration: 2,
      scrollTrigger: {
        trigger: ".post-bg",
        toggleActions,
        start,
        end,
      },
    });

    gsap.to(".post-bg", {
      rotate: -90,
      scale: 0.5,
      scrollTrigger: {
        trigger: ".post-bg",
        scrub: 1,
        start: "top 60%",
        end: "+=800",
      },
    });

    ScrollTrigger.create({
      trigger: ".post-bg",
      start: "top center",
      end: "bottom bottom",
      toggleActions,
      pin: true,
      scrub: 1,
      pinSpacing: false,
      endTrigger: ".main__posts",
      // anticipatePin: true
    });
  }

  // $("[a-class]").each(function () {
  //   const className = $(this).attr("a-class");
  //   addClass(this, className);
  // });
})();

function addSticky(ele) {
  const endTrigger = $(ele).attr("a-end-trigger") || "body";
  ScrollTrigger.create({
    trigger: ele,
    start: "top top",
    end: "bottom top",
    endTrigger,
    scrub: 1,
    pin: true,
    pinSpacing: false,
    anticipatePin: true,
  });
}

function addFadeUp(ele, delay = 0, trigger) {
  addFadeIn(ele, (delay = 0), trigger, { y: 50 });
}

function addFadeRight(ele, delay = 0, trigger) {
  addFadeIn(ele, (delay = 0), trigger, { x: -50 });
}

function addFadeLeft(ele, delay = 0, trigger) {
  addFadeIn(ele, (delay = 0), trigger, { x: 50 });
}

function addFadeIn(ele, delay = 0, trigger, additional = {}) {
  gsap.from(ele, {
    delay: delay / 1000,
    autoAlpha: 0,
    ...additional,
    scrollTrigger: {
      trigger: trigger || ele,
      start,
      end,
      toggleActions,
    },
  });
}

function addBatch(selector, duration = 1, direction, max) {
  const startState = { opacity: 0 };
  const endState = { opacity: 1, y: 0, x: 0 };

  if (direction === "right") {
    startState.x = -50;
  } else if (direction === "left") {
    startState.x = 50;
  } else if (direction === "up") {
    startState.y = 50;
  } else if (direction === "down") {
    startState.y = -50;
  }

  gsap.set(selector, { ...startState, overwrite: true });

  const defaultOpts = {
    stagger: 0.15,
    duration,
    overwrite: true,
    ease: "power1",
  };

  const batchOptions = {
    interval: 0.1, // time window (in seconds) for batching to occur.
    // batchMax, // maximum batch size (targets). Can be function-based for dynamic values
    onEnter: (batch) => gsap.to(batch, { ...endState, ...defaultOpts }),
    // onLeave: (batch) => gsap.to(batch, { ...startState, ...defaultOpts }),
    // onEnterBack: (batch) => gsap.to(batch, { ...endState, ...defaultOpts }),
    onLeaveBack: (batch) => gsap.to(batch, { ...startState, ...defaultOpts }),
    start: "top 80%",
    end,
  };

  if (max) {
    batchOptions.batchMax = parseInt(max);
  }

  ScrollTrigger.batch(selector, batchOptions);
}
// function addClass(ele, className) {
//   ScrollTrigger.create({
//     trigger: ele,
//     start: "top top",
//     end: "bottom top",
//     toggleClass: className || 'active',
//   });
// }

function homePageHeroAnimation() {
  if (!document.querySelector(".home-hero")) return;

  const tl = gsap.timeline({});

  tl.to(".home-hero__title span:nth-child(1)", {
    xPercent: -150,
    onStart: () => $(".menu").addClass("menu--inverse"),
    onReverseComplete: () => $(".menu").addClass("menu--inverse"),
  })
    .to(".home-hero__title span:nth-child(2)", { xPercent: 150 }, 0)
    .to(".home-hero__award", { scale: .5, y: -150 }, 0)
    // .to(".home-hero__title span:nth-child(3)", { xPercent: -150 }, 0)
    .to(".home-hero__img", { scale: 1.5 }, 0)
    .to(
      ".home-hero",
      {
        autoAlpha: 0,
        duration: 1,
        onComplete: () => {
          $(".menu").removeClass("menu--inverse");
        },
      },
      "-=1"
    );

  ScrollTrigger.create({
    animation: tl,
    trigger: ".home-hero",
    start: "top top",
    end: "bottom 60%",
    scrub: 0.5,
    pin: true,
    pinSpacing: false,
    // markers: true,
    // anicipatePin: 1,
  });
}

function textAppear(ele, tl, delay) {
  TweenLite.set(ele, { perspective: 600 });

  const span = $('<span style="display: block;">').html($(ele).html());
  $(ele).html(span);

  const from = {
    y: "130%",
    rotateX: "-40deg",
    opacity: 0,
  };

  const to = {
    y: "0%",
    rotateX: 0,
    opacity: 1,
    duration: 1,
    ease: "power3.out",
  };

  if (tl) {
    tl.fromTo(span, from, to, delay);
  } else {
    gsap.fromTo(span, from, to);
  }
}

function addParallax(ele) {
  const tl = gsap.timeline();

  tl.fromTo(
    ele,
    {
      y: "-30%",
    },
    {
      y: "30%",
      ease: "none",
      duration: 1,
    }
  );

  ScrollTrigger.create({
    animation: tl,
    trigger: ele,
    scrub: 0.5,
    // markers: true,
    start: "top bottom",
    end: function () {
      const end =
        "+=" + ($(ele).height() + document.documentElement.clientHeight);

      return end;
    },
  });
}

function heroAnimation() {
  const tl = gsap.timeline();

  const $pretitle = $(".hero__pretitle");
  const $title = $(".hero__title");
  const $subtitle = $(".hero__subtitle");
  const $text = $(".hero__text");
  const $img = $(".hero__img");

  const delay = "-=0.5";

  if ($title.length) textAppear($title.get(), tl, );
  if ($pretitle.length) textAppear($pretitle.get(), tl, delay);
  if ($subtitle.length) textAppear($subtitle.get(), tl, delay);
  if ($text.length) textAppear($text.get(), tl, delay);

  if ($img.length) {
    tl.from(
      $img.get(),
      {
        autoAlpha: 0,
        duration: 2,
      },
      delay
    );
  }
}
